import React from "react"

const Bio = () => {
  return (
    <div>
      <h1>
        👋 Welcome to my website!
      </h1>
      <p>
        I used to <a href="https://www.memsql.com/blog/running-stored-procedures-on-distributed-systems-with-memsql-6/">build a programming language at MemSQL</a>.
        Now, I'm writting code that will hopefully make global trade 1% more efficient at <a href="https://www.flexport.com/">Flexport</a>.
      </p>
      <p>
        This website is built using GatsbyJS, registered in <a href="https://isnic.is">Iceland</a>,
        and is hosted on AWS with S3 and Cloudfront.
      </p>
      <p>
        Some personal links:
      </p>
      <ul>
        <li>
          <a href="https://www.linkedin.com/in/michael-g-harris/">LinkedIn</a>
        </li>
        <li>
          <a href="https://www.twitter.com/mgharrisdc">Twitter</a>
        </li>
        <li>
          <a href="/resume.pdf">Resume</a>
        </li>
      </ul>
    </div>
  )
}

export default Bio
